import { AEMModel, AEMContainerProps } from '@marriott/mi-ui-library';
import { AemContainerConfig } from '../../molecules/Navigation';
import { ReactNode } from 'react';

interface ItemsModel {
  linkLabel: string;
  openInaNewTab: string;
  linkURL: string;
}
export interface FooterAEMModel extends AEMModel {
  destinationItems: ItemsModel[];
  findhelpItems: ItemsModel[];
  ourCompanyItems: ItemsModel[];
  privacyItems: ItemsModel[];
  socialItems: ItemsModel[];
  workwithusItems: ItemsModel[];
  regions: ItemsModel[];
  id?: string;
}

export interface FooterProps extends AEMContainerProps {
  model: FooterAEMModel;
  cqPath: string;
  children: ReactNode;
  isAuthorMode?: boolean;
  config: AemContainerConfig;
}

export const socialLinks = ['facebook', 'instagram', 'twitter', 'linkedin', 'youtube'];
